var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content-div"},[_c('div',[_c('label',{staticClass:"study-unit-label"},[_vm._v(_vm._s(_vm.study_unit.name))]),_c('p',[(
          _vm.institution &&
          (_vm.institution.internal_use_id == 'ciisa_uss' ||
            _vm.institution.show_competence_unit)
        )?[_c('div',{staticClass:"d-flex"},[_c('SentenceContainer',{attrs:{"Sentence":_vm.study_unit},scopedSlots:_vm._u([{key:"order",fn:function(){return [_c('strong',[_c('span',[_vm._v(" "+_vm._s(_vm.getCompetenceUnitOrder(_vm.study_unit.temp_competence_unit))+_vm._s(_vm.study_unit.order)+" ")])])]},proxy:true}],null,false,3430414780)})],1)]:[_c('SentenceContainer',{attrs:{"Sentence":_vm.study_unit},scopedSlots:_vm._u([{key:"order",fn:function(){return [_c('strong',[_c('span',[_vm._v(" "+_vm._s(_vm.study_unit.order)+" ")])])]},proxy:true}])})]],2),_vm._m(0),(_vm.allows_crud)?_c('div',{directives:[{name:"can",rawName:"v-can",value:('teaching.change_studyunit'),expression:"'teaching.change_studyunit'"}],staticClass:"d-flex justify-content-center"},[(_vm.storedStudyUnit && !_vm.storedStudyUnit.study_unit_locked_view)?[(
            (_vm.institution && _vm.institution.show_base_ra) || _vm.$debug_change_duoc
          )?_c('LinkingCompetenceToRa',{staticClass:"noprint",attrs:{"study_unit_id":_vm.study_unit_id,"egress_profile_matter_id":_vm.egress_profile_matter_id,"allows_crud":_vm.allows_crud}}):_vm._e()]:_vm._e(),_c('ButtonSmall',{attrs:{"tooltip_text":`Editar ${_vm.$getVisibleNames(
          'teaching.ramicro',
          false,
          'RA Micro'
        )}`,"click_button":_vm.openModalStudyUnit,"icon":'square'}}),_c('b-modal',{attrs:{"id":`edit-study-unit-modal-${_vm.study_unit.id}`,"title":`Editar ${_vm.$getVisibleNames(
          'teaching.ramicro',
          false,
          'RA Micro'
        )}`,"size":"lg","hide-footer":"","ignore-enforce-focus-selector":"#sidebar-right"},on:{"hide":function($event){return _vm.hideModaltoMatterProgram()}}},[_c('StudyUnitForm',{attrs:{"egress_profile_matter_id":_vm.egress_profile_matter_id,"matter_id":_vm.matter_id,"StudyUnit":_vm.study_unit,"show_delete_button":true,"show_temp_competence_unit":true},on:{"updated":function($event){return _vm.$bvModal.hide(`edit-study-unit-modal-${_vm.study_unit.id}`)},"deleted":function($event){return _vm.$bvModal.hide(`edit-study-unit-modal-${_vm.study_unit.id}`)}}})],1)],2):_vm._e()])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('section')])
}]

export { render, staticRenderFns }